<template>
  <AppBarLayout
    class="AppConnectionsAppBar"
    v-bind="$attrs"
  >
    <template #header>
      {{ $t('connection.HiveConnections') }}
    </template>

    <template
      v-if="showActions"
      #actions
    >
      <v-btn
        color="primary"
        outlined
        depressed
        :min-width="40"
        @click="getAppConnections"
      >
        <v-icon
          v-text="'mdi-refresh'"
        />
      </v-btn>
      <v-btn
        color="primary"
        class="ml-3"
        depressed
        :loading="actionInProgress"
        :disabled="creationDisabled"
        @click="createAppConnection"
      >
        <v-icon
          class="mr-2"
          v-text="'mdi-plus'"
        />
        {{ $t('connection.AddAppConnection') }}
      </v-btn>
    </template>
  </AppBarLayout>
</template>

<script>
import AppBarLayout from '../layouts/AppBarLayout'

export default {
  name: 'AppConnectionsAppBar',

  components: {
    AppBarLayout,
  },

  inheritAttrs: false,

  props: {
    creationDisabled: { type: Boolean, default: false },
    showActions: { type: Boolean, default: false },
  },

  data() {
    return {
      actionInProgress: false,
    }
  },

  methods: {
    getAppConnections() {
      this.$store.dispatch('appConnections/getAppConnections')
    },

    action(promise) {
      if (this.actionInProgress) {
        return Promise.reject(new Error(this.$t('connection.alreadyInProgress')))
      }
      this.actionInProgress = true
      return Promise.resolve(promise)
        .finally(() => { this.actionInProgress = false })
    },

    createAppConnection() {
      const { $store, action } = this

      const downloaded = $store.dispatch('appConnections/createAppConnection')
        .then(({ configUrl, supposedFilename }) => {
          const a = document.createElement('a')
          a.href = configUrl
          a.download = supposedFilename
          a.click()
        })
        .then(() => $store.commit('$snackbar/setMessage', {
          message: this.$t('connection.NewAppConnectionCreated'),
          timeout: 3000,
          hideCloseBtn: true,
        }))
      return action(downloaded)
        .then(() => { this.$store.dispatch('appConnections/getAppConnections') })
    },
  },
}
</script>
