<template>
  <div>
    <CommonDataTable
      class="AppConnectionsTable"
      :headers="headers"
      :items="appConnections"
      item-key="id"
      fixed-header
      plain-styles
      :sort-by="['created']"
      :sort-desc="[true]"
      scrollable-height="calc(100vh - 64px - 24px - 48px)"
      @contextmenu:row="(appConnection, $event) => openContextMenu($event, appConnection)"
    >
      <template #item.status="{ item: appConnection }">
        <ConnectionStatus
          class="flex-shrink-0"
          style="margin-right: 6px"
          :connection-type="CONNECT.app"
          :connection-id="appConnection.id"
          :connection-name="appConnection.name"
          :last-incoming-pong="appConnection.lastIncomingPong"
          :last-outgoing-ping="appConnection.lastOutgoingPing"
          show-status-name
          tooltip-position="top"
          platform-name="frigate"
        />
      </template>

      <template #item.name="{ item: appConnection, value: name }">
        <CommonTextField
          :ref="`appConnectionName.${appConnection.id}`"
          :value="name"
          class="AppConnectionsTable__name-line text-truncate flex-grow-1"
          :outlined="false"
          :placeholder="$t('connection.AppConnectionName')"
          @change="name = $event"
          @blur="updateAppConnectionName(appConnection, name)"
        />
      </template>

      <template #item._projects="{ item: appConnection }">
        <v-btn
          v-if="appConnection.lastIncomingPong !== null && appConnection.projectsCount >= 0"
          text
          plain
          color="primary"
          min-width="40px"
          :disabled="appConnection.projectsCount === 0"
          @click.stop.prevent="openProjectsList(appConnection)"
          @mousedown.stop
          @touchstart.stop
        >
          {{ appConnection.projectsCount }}
        </v-btn>
        <span
          v-else
          class="d-flex align-center"
        >
          <v-icon
            color="#FFC480"
            v-text="'mdi-alert-box-outline'"
          />
          {{ $t('connection.UploadConfigM') }}
        </span>
      </template>

      <template #item._actions="{ item: appConnection }">
        <v-tooltip
          bottom
          open-delay="300"
          max-width="180px"
          nudge-right="24"
          :disabled="appConnection.lastIncomingPong && duration(appConnection) < 12 * 60 * 1000"
        >
          <template #activator="{ on }">
            <div
              class="d-inline-block"
              v-on="on"
            >
              <v-btn
                text
                color="primary"
                :disabled="!(appConnection.lastIncomingPong && duration(appConnection) < 12 * 60 * 1000)"
                :to="{ name: 'ProjectCreate', query: { appConnectionId: appConnection.id } }"
              >
                <v-icon
                  class="mr-2"
                  v-text="'mdi-plus'"
                />
                {{ $t('connection.AddProject') }}
              </v-btn>
            </div>
          </template>
          <span>{{ $t('connection.ProjectCreationAvailability') }}</span>
        </v-tooltip>

        <v-btn
          icon
          color="#A09EB9"
          @click="openContextMenu($event, appConnection)"
        >
          <v-icon v-text="'mdi-dots-vertical'" />
        </v-btn>
      </template>
    </CommonDataTable>
    <v-menu
      ref="appConnectionMenu"
      v-model="dropdownMenu"
      offset-x
      left
      :nudge-left="8"
      :min-width="136"
      :position-x="x"
      :position-y="y"
    >
      <v-list dense>
        <v-list-item
          :ripple="{ class: 'app-ripple' }"
          @click="focusOnName"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t('connection.EditName') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :ripple="{ class: 'app-ripple' }"
          @click="copyToClipboard"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t('connection.CopyUuid') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :ripple="{ class: 'error--text' }"
          @click="deleteAppConnection"
        >
          <v-list-item-content>
            <v-list-item-title class="error--text">
              {{ $t('connection.Delete') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { PROJECT_CONNECTION_TYPE as CONNECT } from '../constants'
import copyToClipboard from 'copy-to-clipboard'
import moment from 'moment'

import Dialog from '../store/orm/dialog'

import { CommonDataTable, ConnectionStatus } from '@hexway/shared-front'

export default {
  name: 'AppConnectionsTable',

  components: {
    CommonDataTable,
    ConnectionStatus,
  },

  props: {
    appConnections: { type: Array, require: true },
  },

  data() {
    return {
      CONNECT,

      dropdownMenu: false,
      x: 0,
      y: 0,

      selectedConnection: null,
    }
  },

  computed: {

    headers() {
      return [
        {
          text: this.$t('connection.TableStatus'),
          value: 'status',
          style: {
            flex: '1',
            minWidth: '200px',
          },
          sortable: false,
        },
        {
          text: this.$t('connection.TableName'),
          value: 'name',
          style: {
            flex: '1',
            minWidth: '400px',
          },
          sortable: false,
        },
        {
          text: this.$t('connection.TableProjects'),
          value: '_projects',
          sortable: false,
          style: {
            flex: '1',
          },
        },
        // Actions column
        {
          value: '_actions',
          sortable: false,
          align: 'right',
          style: {
            flex: '0 0 200px',
          },
        },
        // For sort
        {
          value: 'created',
          render: false,
        },
      ]
    },

  },

  methods: {
    openContextMenu(event, appConnection = null) {
      const { clientX: x, clientY: y } = event
      this.dropdownMenu = false

      this.x = x
      this.y = y

      this.selectedConnection = appConnection
      this.$nextTick(() => {
        this.dropdownMenu = true
      })
    },

    duration(appConnection) {
      const now = new Date().valueOf()
      const { lastIncomingPong, lastOutgoingPing } = appConnection

      return moment.duration(moment(now).diff(moment(lastIncomingPong || lastOutgoingPing))).asMilliseconds()
    },

    focusOnName() {
      const refName = `appConnectionName.${this.selectedConnection.id}`
      this.$refs[refName].$children[0].focus()
    },

    copyToClipboard() {
      copyToClipboard(this.selectedConnection.id)
      this.$store.commit('$snackbar/setMessage', {
        message: this.$t('connection.CopiedUuid'),
        timeout: 3000,
      })
    },

    updateAppConnectionName(appConnection, name) {
      if (appConnection.name !== name) {
        this.$store.dispatch('appConnections/patchAppConnection', { applicationConnectId: appConnection.id, name })
      }
    },

    async deleteAppConnection() {
      const { $store } = this

      const agreed = await $store.dispatch('confirm/openDialog', {
        title: this.$t('connection.DeleteAppConnectionQ'),
        subtitle: this.$t('connection.ActionImmediateM'),
        consentLabel: this.$t('connection.Delete'),
        consentProps: { color: 'error', depressed: true },
      })

      if (agreed) {
        await $store.dispatch('appConnections/deleteAppConnection', { applicationConnectId: this.selectedConnection.id })
        $store.dispatch('appConnections/getAppConnections')
      }
    },

    async openProjectsList(appConnection) {
      const appConnectionProjects = await this.$store.dispatch('appConnections/getAppConnectionProjects', { applicationConnectId: appConnection.id })
      Dialog.open({
        componentName: 'AppConnectionProjectsDialog',
        props: { appConnection, appConnectionProjects },
      })
    },

  },
}

</script>

<style lang="sass" scoped>
.AppConnectionsTable
  background: #FFFFFF
  padding: 0px 32px
  border-bottom: none

  ::v-deep .CommonDataTableRow:hover
    background-color: transparent !important
  ::v-deep .CommonDataTable__header-text
    font-size: 14px

  &__name-line
    font-weight: 400
    font-size: 14px !important
    line-height: 24px
    border-bottom: 1px transparent solid

    ::v-deep   .v-input__slot::before
      border: none !important

    &:hover
      border-bottom: 1px #C1C1D2 solid

</style>
